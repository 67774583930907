function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"})}
var staticRenderFns = []

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journal/index","lang":"bg","links":[{"name":"За списанието","type":"about"},{"name":"Редколегия","type":"editors"},{"name":"Етика","type":"ethics"},{"name":"Рецензиране","type":"peer"},{"name":"Подаване","type":"instructions"},{"name":"Такси","type":"fees"}],"articleFilters":[{"label":"Заглавие","value":"title"},{"label":"Пълен текст","value":"keywords"},{"label":"Резюме","value":"abstract"}],"authorFilters":[{"label":"Име","value":"fullName"},{"label":"Институция","value":"institutions"},{"label":"ORCID","value":"orcid"}],"availableCards":[{"name":"Издания","route":"issues"},{"name":"Статии","route":"articles"},{"name":"Автори","route":"authors"}],"items":[{"name":"JMAB","issn":"1311-0489"}]},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }