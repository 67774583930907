function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('p'),_vm._v(" "),_c('h2',[_vm._v("About Journal of Mountain Agriculture on the Balkans")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h3',[_vm._v("About")]),_vm._v(" "),_c('p',[_c('strong',[_vm._v("Journal of Mountain Agriculture on the Balkans")]),_vm._v(" is a bilingual journal (English and Bulgarian), in 6 issues yearly as follows: July, August, September, October, November, December, is published by the Research Institute of Mountain Stockbreeding and Agriculture (RIMSA) in Troyan, Bulgaria.")]),_vm._v(" "),_c('p',[_vm._v("JMAB is indexed in the following international data bases:\nCABI (available through the Web of Science platform, CABI Database: CAB Abstracts® and Global Health®), EBSCO, AGRIS, BASE, British Library, eLibrary, JURN, ROAD, WorldCat.")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('h3',[_vm._v("Aims and Scope")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',[_vm._v("Its scope includes basic and applied research relevant to agriculture and stockbreeding in the mountain, foothill and lowland areas in Bulgaria and abroad. JMAB is an international open access web based scientific journal dedicated to the publication and discussion of high-quality investigations in the field of Stockbreeding, Forage Production and Grassland Management, Annual and Perennial Plants, General Agriculture and Processing of Plant and Animal products. It publishes original scientific papers, reviews and short communications, upon double-blind peer review evaluation.")]),_vm._v(" "),_c('p',[_vm._v("The Research Institute of Mountain Stockbreeding and Agriculture in Troyan, which is part of the\nAgricultural Academy, is the owner of the Journal of Mountain Agriculture on the Balkans.")]),_vm._v(" "),_c('p')])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journals/1311-0489/about","lang":"en","title":"About"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }